// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version: "0.0.227",
  production: false,
  environment: "DEV", //DEV,UAT,PRODUCTION
  landing_page_route: "carquotelisting", //Register your Product Landing page Routes here
  all_application: document.location.origin + "/ipdsv2/login/#/allapplication",
  all_application_lob:
    document.location.origin + "/ipdsv2/login/#/alleachapplication/",
  user_management: document.location.origin + "/ipdsv2/login/#/user-mgmt",

  url: "https://ipds1.cloware.in/api/v1/",
  SecUrl: "https://ipds1.cloware.in/api/v1/",
  SecUrl_two: "https://ipds2.cloware.in/api/v1/",
  SecUrl_three: "https://ipds3.cloware.in/api/v1/",
  fuelPath: "https://sdtatadevlmsv2weex.iorta.in/#/loginview?",
  productnavigationurl: "https://ipds1.cloware.in/QMS_MOP/#/mop",
  url_one: "https://ipds1.cloware.in/",
  url_npm: "https://ipds1.cloware.in",
  url_two: "https://ipds2.cloware.in/",
  url_three: "https://ipds3.cloware.in/",
  gmc360Endorsement:
    "https://ipds1.cloware.in/ipdsv2/health/gmc-360/#/gmc360/endorsement",
  ctoken_nav_url: "https://ipds2.cloware.in/impersonate.html",
  aigcv2_product_id: "M000000001004",
  home_product_id: "M000000001023",
  lta2_productId: "M000000000006",
  cyberSheild: "M000000001049",
  personalLinesClaimsUrl: "",
  nonRaterProductId: "",
  bgrNeumorphicProductId: "M000000001126",
  aigCombined: "",
  non_rater_product_group_id: "",
  customerDiaryPath: "/ipdsv2/customerdiary/",
  navigation_token:
    "FC6E5CEB0061B7059F2D38619C03C7B2F4F1CA6E0D29BE5B2C0EA7F7D903C197",
  workman_product_id: "M200000000032",
  workman_path: " https://ipds2.cloware.in/bb1/policy?",
  productArray: [
    //{id : 1,productName : 'Employee Compensation Rater', productId : 'M200000000040', urlLink : 'https://uatipds2.cloware.in/bb1/policy?'},
    {
      id: 1,
      productName: "Employee Compensation Rater",
      productId: "M000000001094",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      search: true,
      quoteSearch: "https://ipds2.cloware.in/impersonate.html",
      convertToProposal:
        "https://ipds1.cloware.in/ipdsv2/qms/ECR/#/ecr/proposal/convert-to-proposal/",
      proposalSearch:
        "https://ipds1.cloware.in/ipdsv2/qms/ECR/#/ecr/proposal/review-proposal/",
      extraParams: true,
      impersonate: true,
      lob: "AIGC",
      allEachApp: false,
    },
    {
      id: 2,
      productName: "Workmen",
      productId: "M200000000032",
      urlLink: "https://ipds2.cloware.in/bb1/policy?",
    },
    {
      id: 3,
      productName: "Personal All Risk (Home protection)",
      productId: "M000000001071",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      impersonate: false,
      extraParams: true,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "Warranty products",
      allEachApp: true,
    },
    {
      id: 4,
      productName: "Smart Care (Extended Warranty)",
      productId: "M000000001069",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      extraParams: true,
      impersonate: false,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "Warranty products",
      allEachApp: true,
    },
    {
      id: 5,
      productName: "Personal All Risk (Mobile)",
      productId: "M000000001072",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      impersonate: false,
      extraParams: true,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "Warranty products",
      allEachApp: true,
    },
    {
      id: 6,
      productName: "Personal All Risk (Screen Damage)",
      productId: "M000000001073",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      impersonate: false,
      extraParams: true,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "Warranty products",
      allEachApp: true,
    },
    {
      id: 7,
      productName: "Professional Indemnity - COI",
      productId: "M000000001109",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      impersonate: false,
      extraParams: true,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "FinancialLine",
      allEachApp: true,
    },
    {
      id: 8,
      productName: "Professional Indemnity - Master policy",
      productId: "M000000001108",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      impersonate: false,
      extraParams: true,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "FinancialLine",
      allEachApp: true,
    },
    {
      id: 9,
      productName: "Group EMI Protect",
      productId: "M000000001104",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      impersonate: false,
      extraParams: true,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "Health",
      allEachApp: true,
    },
    {
      id: 10,
      productName: "Group Credit Secure Plus",
      productId: "M000000001113",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      impersonate: false,
      extraParams: true,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "Health",
      allEachApp: true,
    },
    {
      id: 11,
      productName: "Marine Tool",
      productId: "M000000001135",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      extraParams: true,
      impersonate: true,
      lob: "Marine",
      allEachApp: true,
    },
    {
      id: 12,
      productName: "Extended warranty",
      productId: "M300000000005",
      urlLink: "https://ipds3.cloware.in/impersonate.html",
      search: true,
      searchUrl: "https://ipds3.cloware.in/impersonate.html",
      extraParams: true,
      impersonate: false,
      lob: "Motor",
      allEachApp: true,
    },
    {
      id: 13,
      productName: "PAR Laptop/TV Screen Damage",
      productId: "M000000001140",
      urlLink: "https://ipds2.cloware.in/impersonate.html",
      extraParams: true,
      impersonate: false,
      search: true,
      searchUrl: "https://ipds2.cloware.in/impersonate.html",
      lob: "Warranty products",
      allEachApp: true,
    },
  ],
  commonPath: "https://ipds1.cloware.in/ipdsv2/common/elements",
  commonCssPath: "https://ipds1.cloware.in/ipdsv2/common/common_new.css",
  commonIconPath: "https://ipds1.cloware.in/ipdsv2/common/",
  ipdsv1Navigation:
    "https://pipuat.tataaiginsurance.in/tagichubms/tagic/ipdsHandler.html?request:",
  dAndOProductId: "M000000001006",
  gagProductId: "M100000000014",
  marineToolProductId: "M200000000065",
  comboMaster: [
    {
      lobData1: [
        { id: 0, lob: "PropertyAndEnergy", lob_value: "propertyandenergy" },
      ],
      lobData2: [{ id: 0, lob: "Marine Cargo", lob_value: "marinecargo" }],
      product1: [
        {
          id: 0,
          name: "CPM",
          value: "M200000000007",
          group_name: "propertyandenergy",
        },
      ],
      product2: [
        {
          id: 0,
          name: "MOP",
          value: "M200000000001",
          group_name: "marinecargo",
        },
      ],
    },
  ],
  customerDiaryProducts: [],
  cv_productId: "M300000000004",
  gmcEEMasterId: "M200000000078",
  uwNonSME: "https://tataaig-tst.outsystemsenterprise.com/Flow/Login",
  hdbProducerCode: "8887776662",
  intimidatoryPageHeader: {
    username: "godbtagic",
    password: "godbtagic",
  },
  healthTempProduct: [
    // {
    //   name: "Group Medicare 360",
    //   lob: "health",
    //   icon_name: "GMC_Non_EE.svg",
    //   subProductId: 28006
    // },
    {
      name: "Group Criticare 360",
      lob: "health",
      icon_name: "GMC_Non_EE.svg",
      subProductId: 28000
    },
    {
      name: "Group Medicare",
      lob: "health",
      icon_name: "GMC_Non_EE.svg",
      subProductId: 28024
    }
  ],

  uconBaseUrl : "https://ssx6ev7e4f.execute-api.ap-south-1.amazonaws.com/dev-user-management/api/",
  sourceSystem: "IPDSV2",
  tapPayUrl: "https://dev10bn.tataaig.com/tap/auth",
  criticareProductId: "M200000000028",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
