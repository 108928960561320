<div class="theme-container review-quote user-profile" #target>

    <!-- Page header start -->
    <div class="page-title">
        <div class="nav-btn" [routerLink]="['/allapplication']">
            <svg>
                <use xlink:href="#ICON_Arrow" />
            </svg>
        </div>
        <div class="w-100">
            <div class="page-heading-container">
                <h3 class="head-lg heading">
                    {{ 'userProfile.title' | translate }}
                </h3>
            </div>
            <div class="breadcrum-container">
                <ul class="breadcrum">
                    <li class="head-sm" (click)="globals.goBackToAllApplication()">
                        {{ "allApplication.allProducts.label" | translate }}
                    </li>
                    <li class="head-sm">
                        {{ 'userProfile.title' | translate }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!-- Page header end -->

    <!-- profile page new theme design -->
    <!-- general info -->
    <div class="row">
        <div class="col-md-6">
            <div class="theme-card w-100">
                <div class="card-content new-bg">
                    <div class="user-profile-detail">
                        <div class="row">
                            <div class="col-12">
                                <div class="badge user-profile-status">
                                    <span class="active-dot"></span>
                                    <span>{{ 'userProfile.active.label' | translate }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 ">
                                <div class="user-profile-icon">
                                    <svg class="svg-xl">
                                        <use xlink:href="#ICON_User_Profile" />
                                    </svg>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <span class="section-heading">{{userProfileData?.name||'-'}}</span>
                            </div>
                            <div class="col-12 text-center">
                                <span class="theme-label pr-1">{{userProfileData?.data.role || '-'}}</span>|
                                <span class="theme-label pl-1">{{userProfileData?.broker_code}}</span>
                            </div>
                        </div>
                        <div class="graydivider"></div>
                        <div class="row">
                            <div class="col-md-6 mb-20">
                                <div class="theme-label">{{ 'userProfile.profileValidity' |
                                    translate }}</div>
                                <div class="theme-value">{{userProfileData?.data?.start_Date }} - {{
                                    userProfileData?.data?.end_date}} </div>
                            </div>
                            <div class="col-md-6 mb-20">
                                <div class="theme-label">{{ 'userProfile.mobileNo' |
                                    translate
                                    }}</div>
                                <div class="theme-value">{{userProfileData?.data?.mobile_no||'-'}}</div>
                            </div>
                            <div class="col-12 mb-20">
                                <div class="theme-label">{{ 'userProfile.primaryAltEmailId' |
                                    translate
                                    }}</div>
                                <div class="theme-value">{{userProfileData?.data?.email||'-' }} / {{
                                    userProfileData?.data?.alt_email||'-'}}</div>
                            </div>
                            <div class="col-md-6">
                                <div class="theme-label">{{ 'userProfile.ipAddress' |
                                    translate
                                    }}</div>
                                <div class="theme-value">{{userProfileData?.data.ip}}</div>
                            </div>
                            <div class="col-md-6">
                                <div class="theme-label">Remaining Credit Limit</div>
                                <div class="theme-value">
                                    <span><i class="fa fa-inr"></i></span>
                                    <span>{{callpremiumcomma(depositbalance)}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="theme-card w-100">
                <div class="card-content h-100">
                    <div class="row">
                        <div class="col-12">
                            <label class="section-heading">
                                {{ 'userProfile.generalInfo.title' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="row pt-20">
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">{{ 'userProfile.revalidation'
                                |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.status==0?'Active':'Inactive'}}</div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">Category</div>
                            <div class="theme-value">{{userProfileData?.data?.category||'-'}}</div>
                        </div>
                        <div class="col-md-6 mb-20" *ngIf="userProfileData?.data?.txt_posp_code">
                            <div class="theme-label">{{ 'userProfile.externalPospCode'
                                |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.data?.txt_posp_code||'-'}}</div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">{{
                                'userProfile.type' |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.user_type||'-'}}</div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">{{ 'userProfile.pospPan'
                                |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.data?.txt_pan_no||'-'}}</div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">{{
                                'userProfile.emplpyeeCode' |
                                translate }}</div>
                            <div class="theme-value">{{'-'}}</div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">{{ 'userProfile.pospAadhaar'
                                |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.data?.txt_aadhar_number||'-'}}</div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">{{ 'userProfile.solId'
                                |
                                translate }}</div>
                            <div class="theme-value">{{sol_id||'-'}}</div>
                        </div>
                        <div class="col-md-6 mb-20">
                            <div class="theme-label">{{
                                'userProfile.agentCode' |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.agent_code||'-'}}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="theme-label">{{
                                'userProfile.brokerCode' |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.data?.broker_code||'-'}}</div>
                        </div>
                        <div class="col-md-6">
                            <div class="theme-label">{{
                                'userProfile.parentCode' |
                                translate }}</div>
                            <div class="theme-value">{{userProfileData?.data?.parent_code||'-'}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- mapped payment modes -->
    <ng-container *ngIf="paymentOptions?.length > 0">
        <div class="row">
            <div class="col-12">
                <div class="theme-card w-100">
                    <div class="card-content">
                        <div class="row">
                            <div class="col-12">
                                <div class="section-heading">
                                    Mapped Payment Modes
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-lg-3 col-xl-3 mb-20" *ngFor="let item of paymentOptions">
                                <span>
                                     <img src="{{service.commonIconPath}}assets/images/Payment_Icons/{{paymentIcon[item]}}"
                                        alt="Payment Icon">
                                </span>
                                <span class="theme-value pl-2">{{item}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <!-- bottom cards -->
    <div class="row">
        <div class="col-12">
            <div class="mb-5">
                <div class="location-font text-uppercase pb-2">Mapped to my Profile</div>
                <div>
                    <span class="section-heading pr-1">{{sessionLocation}}<span *ngIf="sessionState">,
                            {{sessionState}}</span></span>|<button class="btn link-btn pl-1"
                        (click)="openViewLocation(view_location_template)">View
                        Location</button>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-3" *ngFor="let lob of Object.keys(productsObject); let i=index">
            <div class="cards-wrapper pb-4" [class.active]="currentIndex == i && showCardDetail">
                <div class="card-content cursor-pointer" (click)="openLargeCard(i)">
                    <div class="preview-structure">
                        <div class="row">
                            <div class="col-12">
                                <img src="{{service.commonIconPath}}/assets/images/{{iconspath[lob]}}"
                                    alt="">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="location-font text-uppercase pt-5">
                                    {{lob}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="theme-value d-inline-block line-clamp pt-10"
                                    *ngFor="let item of this.productsObject[lob] | slice:0:1 ;let j = index">
                                    <span>{{productsObject[lob][j]['name']}},&nbsp;....</span>
                                </div>
                            </div>
                        </div>
                        <div class="row ">
                            <div class="pt-20 pl-4">
                                <button class="btn link-btn text-start">View
                                    Details</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-content bigcard" (click)="openLargeCard(i)">
                    <div class="preview-structure">
                        <div class="row">
                            <div class="col-12">
                                <img src="{{service.commonIconPath}}/assets/images/{{iconspath[lob]}}"
                                    alt="">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="location-font text-uppercase py-5">
                                    {{lob}}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6" *ngFor="let item of this.productsObject[lob];let j = index">
                                        <div class="">
                                            <ng-container *ngIf="(lob !== 'lta' && lob !== 'travel') || plans.length == 0"><i class="fa fa-angle-double-right" aria-hidden="true"></i></ng-container>
                                            <span class="subprod_name text-break pr-1" [class.pl-1]="(lob !== 'lta' && lob !== 'travel') || plans.length == 0">{{productsObject[lob][j]['name']}}</span>
                                            <span class="pull-right" *ngIf="(lob === 'lta' || lob === 'travel') && plans.length > 0"
                                                (click)="openViewPayemnt(view_detail_template,lob)">
                                                <svg class="svg-xs rotate-90">
                                                    <use xlink:href="#ICON_downarrow" />
                                                </svg>
                                            </span>
                                        </div>
                                        <div class="theme-label" *ngIf="(lob === 'lta' || lob === 'travel') && plans.length > 0">
                                            <span class="theme-value">{{plans.length}}</span> <span
                                                class="pr-1 pl-1">Plans</span> |
                                            <span class="theme-value pl-1">{{paymentOptions.length}}</span> Payment
                                            Modes
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="pl-4 pt-5">
                                <button class="btn link-btn text-start" *ngIf="showCardDetail">Hide
                                    Details</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Location list modal start -->
<ng-template #view_location_template>
    <div class="modal-container">
        <div class="detail-container-modal modal-md modal-form contact-detail-modal location-modal">
            <div class="modal-header ">
                <label class="modal-title pull-left head-lg text-uppercase">
                    Mapped Locations
                </label>
                <div class="close-icon" (click)="modalRefLocation.hide()">
                    <svg>
                        <use xlink:href="#ICON_Cross" />
                    </svg>
                </div>
            </div>
            <div class="modal-body has-form">
                <!-- <div class="theme-value mb-2" *ngFor="let location of locationlist; let i = index">{{(i+1)+".
                    "+location}}</div> -->
                <div class="row">
                    <div class="col-12">
                        <input type="text" placeholder="Search" (keyup)="filterSearch()" [(ngModel)]="searchContent"
                            #search="ngModel" class="form-control icon-image">
                    </div>
                </div>
                <div class="row mt-5 graydivider">
                    <div class="col-md-6 col-lg-4 mb-20" *ngFor="let location of filteredData; let i = index">
                        <span class="theme-label"
                            [class.fontbold]="sessionLocation == location">{{i+1}}.&nbsp;&nbsp;{{location}}</span>
                        <span class="pl-2" *ngIf="sessionLocation == location">
                            <svg class="svg-xs">
                                <use xlink:href="#ICON_Done_Tick" />
                            </svg>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Location list modal end -->

<!-- Payment Mode modal start -->
<ng-template #view_payment_template>
    <div class="modal-container">
        <div class="detail-container-modal modal-md modal-form contact-detail-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left">
                    <span class="text-uppercase">{{ 'userProfile.paymentOptions' | translate }}</span>
                </h4>
                <div class="close-icon" (click)="modalRefPayment.hide()">
                    <svg>
                        <use xlink:href="#ICON_Cross" />
                    </svg>
                </div>
            </div>
            <div class="modal-body has-form">
                <div class="theme-value mb-2" *ngFor="let payment_mode of paymentOptions; let i = index">
                    {{i+1+'.'+payment_mode}}</div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Payment Mode modal end -->

<!-- Product Detail Modal -->
<ng-template #view_detail_template>
    <div class="modal-container">
        <div class="detail-container-modal modal-md modal-form contact-detail-modal location-modal">
            <div class="modal-header">
                <h4 class="modal-title pull-left head-lg">
                    <span class="text-uppercase">{{lobshown}} | <span class="section-heading pr-1">{{sessionLocation}}<span *ngIf="sessionState">,
                        {{sessionState}}</span></span></span>
                </h4>
                <div class="close-icon" (click)="modalRefPayment.hide()">
                    <svg>
                        <use xlink:href="#ICON_Cross" />
                    </svg>
                </div>
            </div>
            <div class="modal-body has-form">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>Product</th>
                            <th>Plans</th>
                            <th>Zones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of planZoneArray">
                            <td>{{item.product}}</td>
                            <td>{{item.prod_plan}}</td>
                            <td>{{item.zone}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>
<!-- Product Detail Modal end-->